<template>
  <div>
    <small>Weitere Informationen</small>
    <b-row v-for="(value, key) in taskData" :key="key">
      <b-col class="text-left text-bold">
        {{ key }}
      </b-col>
      <b-col class="text-right">
        <span v-if="value === null">-</span>
        <span v-else>{{ value }}</span>
      </b-col>
    </b-row>
    <hr class="divider"/>
    <b-row v-for="(value, key) in taskTime" :key="key">
      <b-col class="text-left text-bold">
        {{ key }}
      </b-col>
      <b-col class="text-right">
        <span v-if="value === null">-</span>
        <span v-else-if="key === 'Geplante Startzeit'">{{ value | datetime }}</span>
        <span v-else-if="key === 'Startzeit'">{{ value | datetime }}</span>
        <span v-else-if="key === 'Abschlusszeit'">{{ value | datetime }}</span>
        <span v-else>{{ value }}</span>
      </b-col>
    </b-row>
    <hr class="divider"/>
    <b-row v-for="(value, key) in taskInfo" :key="key">
      <b-col class="text-left text-bold">
        {{ key }}
      </b-col>
      <b-col class="text-right">
        <span v-if="value === null">-</span>
        <span v-else>{{ value }}</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "TaskDetails",
  props: ['task'],
  computed: {
    taskData() {
      return {
        'ID': this.task.id,
        'Bezeichnung': this.task.displayname,
        'Kategorie': this.task.category,
        'Status': this.task.task_status.displayname,
        'Ersteller': this.task.user.username
      }
    },
    taskTime() {
      return {
        'Geplante Startzeit': this.task.time_execute_scheduled,
        'Startzeit': this.task.time_execute_start,
        'Abschlusszeit':this.task.time_execute_end
      }
    },
    taskInfo() {
      let param = this.task.command_parameter
      if(this.task.category === 'Sync' && !isNaN(param)) {
        param = param / 1000 + ' KByte/s'
      }
      return {
        'Fortschritt': (this.task.progress_current && this.task.progress_max) ? this.task.progress_current + '/' + this.task.progress_max : null,
        'Fortschrittsmeldung': this.task.progress_message,
        'Fehlermeldung': this.task.error_message,
        'Parameter': param
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/Tasks.scss';
</style>
