<template>
  <b-progress :max="1" striped>
    <b-progress-bar :value="1" class="text-white text-border px-1 task-progress task-entry">
      <span v-if="task.type === 'install'" class="text-left progress-bar-fix-action-history">
        {{ task.displayname }} - Installation
      </span>
      <span v-else-if="task.type === 'deinstall'" class="text-left progress-bar-fix-action-history">
        {{ task.displayname }} - Deinstallation
      </span>
      <span v-else-if="task.type === 'sync'" class="text-left progress-bar-fix-action-history">
        {{ task.displayname }} - Sync
      </span>
      <span v-else-if="task.type === 'hostname'" class="text-left progress-bar-fix-action-history">
        {{ task.displayname }} - {{ task.command_parameter }}
      </span>
      <span v-else class="text-left progress-bar-fix-action-history">{{ task.displayname }}</span>
      <span class="text-right">
        <b-icon-stopwatch-fill class="mr-1"/>
        {{ task.time_execute_scheduled | datetime }}
      </span>
    </b-progress-bar>
    <b-tooltip :target="'tt-task-d-pending-' + task.id" triggers="hover">
      Gesetzt von {{ task.user.username }}
    </b-tooltip>
  </b-progress>
</template>

<script>
export default {
  name: "TaskPending",
  props: ['task']
}
</script>

<style lang="scss" scoped>
@import '../../../../../assets/styles/Tasks.scss';
</style>
