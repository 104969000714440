<template>
  <b-row @click="getDetails">
    <b-col class="text-left pl-1">
      <span v-if="task.type === 'install'">{{ task.displayname }} - Installation</span>
      <span v-else-if="task.type === 'deinstall'">{{ task.displayname }} - Deinstallation</span>
      <span v-else-if="task.type === 'sync'">{{ task.displayname }} - Sync</span>
      <span v-else-if="task.type === 'hostname'">
        {{ task.displayname }} - {{ task.command_parameter }}
      </span>
      <span v-else>{{ task.displayname }}</span>
    </b-col>
    <b-col class="text-right pr-1">
      {{ task.time_execute_end | datetime }}
    </b-col>
    <b-tooltip :target="'tt-task-d-finished-' + task.id" triggers="hover">
      Gesetzt von {{ task.user.username }}
    </b-tooltip>
  </b-row>
</template>

<script>
export default {
  name: "TaskFinished",
  props: ['task'],
  methods: {
    getDetails() {
      this.$emit('get-details')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
