<template>
  <div>
    <b-container fluid class="p-0 mt-0">
      <b-row>
        <b-col cols="2"/>
        <b-col cols="8" style="font-size: 14px">
          <SubHeader>
            <TaskFilter class="mt-2" :filteredCount="filteredCount" @search-changed="setSearch"
                        @filter-options="setFilterOptions"/>
          </SubHeader>
          <b-list-group :class="listGroupClasses" style="font-size: 14px">
            <b-row cols="1">
              <b-col>
                <b-list-group-item v-for="entry in taskList" :key="entry.id" class="list-item">
                  <TaskRow :entry="entry" @refresh="refresh"/>
                </b-list-group-item>
              </b-col>
            </b-row>
          </b-list-group>
          <b-row v-if="taskList.length === 0" cols="1">
            <b-col>
              <h5 class="text-secondary text-center mt-5">Keine Daten vorhanden</h5>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="2"/>
      </b-row>
    </b-container>
    <ScrollToTop/>
  </div>
</template>

<script>
import _ from "lodash";
import {postRequest} from "@/modules/requests";
import {mapActions, mapGetters} from "vuex";
import ScrollToTop from "@/components/utility/ScrollToTop";
import SubHeader from "@/components/structure/SubHeader";
import TaskRow from "@/components/lists/taskList/TaskRow";
import TaskFilter from "@/components/lists/taskList/TaskFilter.vue";

export default {
  name: "TaskList",
  components: {
    TaskFilter,
    SubHeader,
    TaskRow,
    ScrollToTop,
  },
  data() {
    return {
      tasks: [],
      search: '',
      filteredCount: 0,
      filterOptions: null
    }
  },
  methods: {
    ...mapActions(['selectSite']),
    ...mapActions(['setLoadingState']),
    setSearch(data) {
      this.search = data
    },
    setFilterOptions(data) {
      this.filterOptions = data
      this.refresh()
    },
    async refresh() {
      this.setLoadingState(true)
      await this.getTasks()
      await window.scrollTo(0, 0)
      this.setLoadingState(false)
    },
    async getTasks() {
      this.tasks = []
      await postRequest('taskList/' + this.siteSelected.shortname, this.filterOptions, this, null)
          .then((response) => {
            this.filteredCount = response.data.task_count
            this.tasks = response.data.pcs
          })
    }
  },
  computed: {
    ...mapGetters(['siteSelected']),
    ...mapGetters(['userThemeLight']),
    listGroupClasses() {
      if(this.userThemeLight) {
        return ['mt-2']
      }
      return ['text-white', 'mt-2']
    },
    taskList() {
      return _.filter(this.tasks, (entry) => {
        if(entry.hostname.toLowerCase().includes(this.search.toLowerCase())) {
          return true
        }
      })
    }
  },
  beforeMount() {
    if(this.$route.query && this.$route.query.site) {
      this.selectSite(this.$route.query.site)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/Tasks';
</style>
