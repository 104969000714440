<template>
  <b-progress :max="task.progress_max" animated class="task-progress task-entry">
    <b-progress-bar :value="task.progress_current" class="text-white text-border pl-1">
      <span v-if="task.type === 'install'" class="text-left progress-bar-fix-action-history">
        {{ task.displayname }} - Installation
      </span>
      <span v-else-if="task.type === 'deinstall'" class="text-left progress-bar-fix-action-history">
        {{ task.displayname }} - Deinstallation
      </span>
      <span v-else-if="task.type === 'sync'" class="text-left progress-bar-fix-action-history">
        {{ task.displayname }} - Sync
      </span>
      <span v-else-if="task.type === 'hostname'" class="text-left progress-bar-fix-action-history">
        {{ task.displayname }} - {{ task.command_parameter }}
      </span>
      <span v-else class="text-left progress-bar-fix-action-history">{{ task.displayname }}</span>
    </b-progress-bar>
    <b-tooltip :target="'tt-task-d-running-' + task.id" triggers="hover">
      Gesetzt von {{ task.user.username }}
    </b-tooltip>
  </b-progress>
</template>

<script>
export default {
  name: "TaskRunning",
  props: ['task']
}
</script>

<style lang="scss" scoped>
@import '../../../../../assets/styles/Tasks.scss';
</style>
