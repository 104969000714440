<template>
  <div>
    <b-row @mouseenter="hover = true" @mouseleave="hover = false">
      <b-col cols="11" class="text-left pl-1">
        <TaskType :task="task" @get-details="getDetails"/>
        <b-row>
          <b-col v-if="expand" cols="12" class="task-details">
            <TaskDetails :task="task"/>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="1" v-if="task.task_status.status === 'pending' || (task.task_status.status === 'running' && task.type === 'sync')">
        <b-icon-pencil-fill variant="primary" :id="'editBtn-' + task.id" class="computer-icon clickable"
                            @click="openEditTask"/>
        <b-tooltip :target="'editBtn-' + task.id" triggers="hover">Bearbeiten</b-tooltip>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TaskType from "@/components/lists/taskList/tasks/TaskType";
import TaskDetails from "@/components/lists/taskList/tasks/TaskDetails";

export default {
  name: "TaskEntry",
  components: {
    TaskDetails,
    TaskType
  },
  props: ['task'],
  data() {
    return {
      hover: false,
      expand: false,
    }
  },
  methods: {
    openEditTask() {
      this.$emit('edit-task', this.task)
    },
    async getDetails() {
      this.expand = !this.expand;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/styles/Tasks.scss';
</style>
