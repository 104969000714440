<template>
  <div>
    <b-row v-if="task.task_status.status === 'pending'" :id="'tt-task-d-pending-' + task.id" class="task-pending">
      <b-col cols="12" class="p-0 clickable" @click="getDetails">
        <TaskPending :task="task"/>
      </b-col>
    </b-row>
    <b-row v-if="task.task_status.status === 'running'" :id="'tt-task-d-running-' + task.id" class="task-running">
      <b-col cols="12" class="p-0 clickable" @click="getDetails">
        <TaskRunning :task="task"/>
      </b-col>
    </b-row>
    <TaskFinished :task="task" v-if="task.task_status.status === 'finished'" :id="'tt-task-d-finished-' + task.id"
                  class="task-finished task-entry clickable" @get-details="getDetails"/>
    <TaskError :task="task" v-if="task.task_status.status === 'error'" :id="'tt-task-d-error-' + task.id"
               class="task-error task-entry clickable" @get-details="getDetails"/>
  </div>
</template>

<script>
import TaskError from "@/components/lists/taskList/tasks/taskType/TaskError";
import TaskPending from "@/components/lists/taskList/tasks/taskType/TaskPending";
import TaskRunning from "@/components/lists/taskList/tasks/taskType/TaskRunning";
import TaskFinished from "@/components/lists/taskList/tasks/taskType/TaskFinished";

export default {
  name: "TaskType",
  components: {TaskError, TaskFinished, TaskRunning, TaskPending},
  props: ['task'],
  methods: {
    getDetails() {
      this.$emit('get-details')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/Tasks.scss';
</style>
