<template>
  <div>
    <b-input-group>
      <b-form-input v-model="inputTime" @input="inputChange" type="text" :state="timeState"/>
      <b-input-group-append>
        <b-form-timepicker v-model="inputTime" @input="inputChange" button-only dropleft now-button locale="de"/>
      </b-input-group-append>
    </b-input-group>
    <b-form-invalid-feedback :state="timeState">Das Zeitformat ist: HH:mm!</b-form-invalid-feedback>

    <b-button variant="primary" class="mt-2 mr-2" @click="setTime({hour: 9, minute: 0})">09:00</b-button>
    <b-button variant="primary" class="mt-2 mr-2" @click="setTime({hour: 12, minute: 0})">12:00</b-button>
    <b-button variant="primary" class="mt-2 mr-2" @click="setTime({hour: 16, minute: 15})">16:15</b-button>
    <b-button variant="primary" class="mt-2 mr-2" @click="setTime({hour: 17, minute: 20})">17:20</b-button>
    <b-button variant="primary" class="mt-2 mr-2" @click="setTime()">Sofort</b-button>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: 'Timepicker',
  data() {
    return {
      inputTime: dayjs().format('HH:mm')
    }
  },
  methods: {
    inputChange(event) {
      if(dayjs(event, 'HH:mm:ss', true).isValid()) {
        event = dayjs(event, 'HH:mm:ss', true).format('HH:mm')
      }
      if(dayjs(event, 'HH:mm', true).isValid()) {
        this.$emit('time-changed', dayjs(event, 'HH:mm', true).format('HH:mm'))
        this.inputTime = dayjs(event, 'HH:mm', true).format('HH:mm')
      }
    },
    setTime(timeStamp) {
      this.$emit('time-changed', dayjs(timeStamp).format('HH:mm'));
      this.inputTime = dayjs(timeStamp).format('HH:mm');
    },
  },
  computed: {
    timeState() {
      let value = dayjs(this.inputTime, 'HH:mm', true).isValid() ? null : false
      if(value === false) {
        this.$emit('datetime-invalid', {value: true, type: 'time'})
      }
      else {
        this.$emit('datetime-invalid', {value: false, type: 'time'})
      }
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
