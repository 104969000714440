<template>
  <div>
    <div>{{ entry.hostname }}</div>
    <b-row cols="1" class="mt-2">
      <div v-for="task in entry.tasks" :key="task.id" class="px-3 mb-1" style="font-size: 12px">
        <TaskEntry :task="task" class="ml-3 mr-2" @edit-task="editTask"/>
      </div>
    </b-row>
    <EditTaskModal :task=modalTask :pcUuid="entry.pc_uuid" :pcHostname="entry.hostname" @refresh="refresh"/>
  </div>
</template>

<script>
import TaskEntry from "@/components/lists/taskList/TaskEntry";
import EditTaskModal from "@/components/lists/computerList/tasks/editTaskModal/EditTaskModal";

export default {
  name: "TaskRow",
  props: ['entry'],
  components: {
    TaskEntry,
    EditTaskModal
  },
  data() {
    return {
      search: '',
      modalTask: null
    }
  },
  methods: {
    editTask(task) {
      this.modalTask = task;
      this.modalTask.id = task.id;
      this.modalTask.command_parameter = task.command_parameter;
      this.modalTask.time_execute_scheduled = task.time_execute_scheduled;
      this.modalTask.issuer = task.user.username;
      this.$bvModal.show('editTaskModal-' + this.entry.pc_uuid);
    },
    refresh() {
      this.$emit('refresh')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
