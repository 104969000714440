<template>
  <div>
    <b-input-group>
      <b-form-input v-model="inputDate" @input="inputChange" type="text" :state="dateState"/>
      <b-input-group-append>
        <b-form-datepicker v-model="datepickerDate" @input="datepickerChange" button-only dropleft locale="de"/>
      </b-input-group-append>
    </b-input-group>
    <b-form-invalid-feedback :state="dateState">Das Datumsformat ist: DD.MM.JJJJ!</b-form-invalid-feedback>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: 'Datepicker',
  data() {
    return {
      inputDate: dayjs().format('DD.MM.YYYY'),
      datepickerDate: dayjs().format('YYYY-MM-DD')
    }
  },
  methods: {
    inputChange(event) {
      if(dayjs(event, 'DD.MM.YYYY', true).isValid()) {
        this.$emit('date-changed', dayjs(event, 'DD.MM.YYYY', true).format('YYYY-MM-DD'))
        this.datepickerDate = dayjs(event, 'DD.MM.YYYY', true).format('YYYY-MM-DD')
      }
    },
    datepickerChange(event) {
      if(dayjs(event, 'YYYY-MM-DD', true).isValid()) {
        this.$emit('datepicker-changed', dayjs(event).format('YYYY-MM-DD'))
        this.inputDate = dayjs(event).format('DD.MM.YYYY')
      }
    },
  },
  computed: {
    dateState() {
      let value = dayjs(this.inputDate, 'DD.MM.YYYY', true).isValid() ? null : false
      if(value === false) {
        this.$emit('datetime-invalid', {value: true, type: 'date'})
      }
      else {
        this.$emit('datetime-invalid', {value: false, type: 'date'})
      }
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
