<template>
  <div>
    <b-input-group>
      <b-form-input v-model="speed" @input="inputChange" type="number" :state="syncSpeedState()"/>
      <template #append>
        <b-form-select v-model="dataRate" :options="options"/>
      </template>
    </b-input-group>
    <b-form-invalid-feedback :state="syncSpeedState()">{{ message }}</b-form-invalid-feedback>
  </div>
</template>

<script>
export default {
  name: "SyncSpeed",
  props: ['syncSpeed'],
  data() {
    return {
      speed: this.syncSpeed,
      dataRate: 'k',
      options: [
        { value: 'k', text: 'KByte/s' },
        { value: 'm', text: 'MByte/s' },
      ],
      message: ''
    }
  },
  methods: {
    syncSpeedState() {
      let x = this.speed
      if(this.dataRate === 'k') { x = x * 1000 }
      if(this.dataRate === 'm') { x = x * 1000000 }
      if(x > 100000000) {
        this.message = 'Die Geschwindigkeit ist zu hoch (maximal 100MByte/s)!'
        this.$emit('sync-speed-invalid', true)
        return false
      }
      if(x < 250000) {
        this.message = 'Die Geschwindigkeit ist zu niedrig (mindestens 250 KByte/s)!'
        this.$emit('sync-speed-invalid', true)
        return false
      }
      this.$emit('sync-speed-invalid', false)
      return null
    },
    inputChange() {
      let x = this.speed
      if(this.dataRate === 'k') { x = x * 1000 }
      if(this.dataRate === 'm') { x = x * 1000000 }
      this.$emit('sync-speed-changed', x)
    }
  },
  created() {
    this.inputChange()
  }
}
</script>

<style lang="scss" scoped>
</style>
